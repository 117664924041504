var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailInfo",
          attrs: { title: "아차사고 기본정보" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "아차사고번호",
                    value: _vm.nearAccident.iimNearAccidentNo,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "아차사고명",
                    value: _vm.nearAccident.iimNearAccidentName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "아차사고종류",
                    value: _vm.nearAccident.iimNearKindName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "발생일시",
                    value: _vm.nearAccident.occurrenceDt,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "심사" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  !_vm.isApprDisabled
                    ? _c("c-appr-btn", {
                        ref: "appr-btn",
                        attrs: {
                          name: "danger-journal-appr-btn",
                          editable: _vm.editable,
                          approvalInfo: _vm.approvalInfo,
                        },
                        on: {
                          beforeApprAction: _vm.saveDataAppr,
                          callbackApprAction: _vm.approvalCallback,
                          requestAfterAction: _vm.getDetail,
                        },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.nearAccident,
                          mappingType: "PUT",
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveNearAccident,
                          btnCallback: _vm.saveNearAccidentCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "심사점수",
                    type: "number",
                    name: "auditScore",
                  },
                  model: {
                    value: _vm.nearAccident.auditScore,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "auditScore", $$v)
                    },
                    expression: "nearAccident.auditScore",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "포상금",
                    suffix: "원",
                    type: "number",
                    name: "bounty",
                  },
                  model: {
                    value: _vm.nearAccident.bounty,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "bounty", $$v)
                    },
                    expression: "nearAccident.bounty",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    rows: 8,
                    label: "심사 의견",
                    name: "auditOpinion",
                  },
                  model: {
                    value: _vm.nearAccident.auditOpinion,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "auditOpinion", $$v)
                    },
                    expression: "nearAccident.auditOpinion",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }