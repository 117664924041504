<template>
  <q-form ref="editForm">
      <c-card title="아차사고 기본정보" class="cardClassDetailInfo">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="아차사고번호" :value="nearAccident.iimNearAccidentNo"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="아차사고명" :value="nearAccident.iimNearAccidentName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="아차사고종류" :value="nearAccident.iimNearKindName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="발생일시" :value="nearAccident.occurrenceDt"></c-label-text>
          </div>
        </template>
      </c-card>
      <c-card title="심사" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline>
            <c-appr-btn 
              v-if="!isApprDisabled"
              ref="appr-btn"
              name="danger-journal-appr-btn"
              :editable="editable"
              :approvalInfo="approvalInfo"
              @beforeApprAction="saveDataAppr"
              @callbackApprAction="approvalCallback"
              @requestAfterAction="getDetail"
            />
            <c-btn
              v-if="editable&&!disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="nearAccident"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveNearAccident"
              @btnCallback="saveNearAccidentCallback"
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <c-text
              :editable="editable"
              :disabled="disabled"
              label="심사점수"
              type="number"
              name="auditScore"
              v-model="nearAccident.auditScore">
            </c-text>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <c-text
              :editable="editable"
              :disabled="disabled"
              label="포상금"
              suffix="원"
              type="number"
              name="bounty"
              v-model="nearAccident.bounty">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="8"
              label="심사 의견"
              name="auditOpinion"
              v-model="nearAccident.auditOpinion">
            </c-textarea>
          </div>
        </template>
      </c-card>
  </q-form>
</template>
<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'nearAudit',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
      }),
    },
    nearAccident: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
        plantCd: '',
        iimNearAccidentNo: '',
        iimNearAccidentName: '',
        iimStatusNearCd: '',
        reportDeptCd: '',
        reportUserId: '',
        reportDt: '',
        iimNearKindCds: '',
        iimGradeCd: null,
        discovererId: '',
        occurrenceDt: '',
        occurrenceLocation: '',
        reviewDeptCd: '',
        weatherCd: 'WC000001',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        iimNearDiscernCds: null,
        iimNearFunctionCds: null,
        iimNearFeelCds: null,
        iimNearEquipCds: null,
        iimNearManageCds: null,
        processCd: '',
        frequencyRisk: '',
        strengthRisk: '',
        totalRisk: '',
        ramRiskAssessmentPlanId: '',
        auditScore: null,
        auditOpinion: '',
        bounty: null,
        approvalRequestStatusCd: '', // 결재관련 결재상태 요청승인
        sysApprovalRequestRequestId: '', // 결재관련 결재요청코드 요청승인
        approvalReviewStatusCd: '', // 결재관련 결재상태 검토승인
        sysApprovalReviewRequestId: '', // 결재관련 결재요청코드 검토승인
        approvalAuditStatusCd: '', // 결재관련 결재상태 심사승인
        sysApprovalAuditRequestId: '', // 결재관련 결재요청코드 심사승인
        preventions: [],
      }),
    },
    isApprDisabled: {
      type: Boolean,
      default: false
    },
    isOld: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      editable: true,
      isSave: false,
      isApproval: false,
      saveUrl: '',
    };
  },
  computed: {
    disabled() {
      return !this.editable 
        || (this.isOld 
          && (this.nearAccident.iimStatusNearCd !== 'ISNC000003'
            || this.nearAccident.approvalAuditStatusCd === 'ASC0000001'
            || this.$_.indexOf(this.$store.getters.auths, 'SAGI000022') === -1
          )
        )
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.nearAccident.sysApprovalAuditRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.nearAccident.approvalAuditStatusCd, 
        apprEditable: !this.disabled && this.isOld, // 결재버튼 활성화 기준
        param: this.nearAccident, // 결재 param
        approvalUrl: transactionConfig.sop.iim.accident.near.update.url, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000024', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          iimNearAccidentId: this.popupParam.iimNearAccidentId,
          iimStatusNearCd: this.nearAccident.iimStatusNearCd,
          isApprDisabled: true,
        },
        approvalRequestName: '[아차사고 심사승인] ' + this.nearAccident.iimNearAccidentName, // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.iimNearAccidentId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
  },    
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.iim.accident.near.update.url;
      // code setting
      // list setting
    },
    getDetail() {
      this.$emit('getDetail')
    },
    saveNearAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.nearAccident.regUserId = this.$store.getters.user.userId
              this.nearAccident.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveNearAccidentCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail', 'planOpinion')
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        // 결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?
        message: 'MSG0000430',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.nearAccident.regUserId = this.$store.getters.user.userId
          this.nearAccident.chgUserId = this.$store.getters.user.userId

          this.isApproval = !this.isApproval
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
  }
};          
</script>